<template>
  <div class="w-full h-full absolute bg-black/50 text-center md:py-4">
    <div
      v-if="editablePolls.length > 0"
      ref="popup"
      class="bg-white md:rounded-lg inline-block md:w-5/6 relative p-4 text-left max-h-full overflow-auto"
    >
      <font-awesome-icon
        icon="xmark"
        class="text-3xl text-primary cursor-pointer float-right m-3"
        @click="$emit('close')"
      />
      <div v-for="poll in editablePolls" :key="poll" class="pb-10">
        <span class="text-2xl font-bold">{{ poll.title }}</span>

        <pre class="whitespace-break-spaces font-sans text-left w-full">{{
          poll.description
        }}</pre>

        <div v-for="option in poll.options" :key="option">
          <input
            v-if="poll.multiple"
            type="checkbox"
            :id="poll.id + '_' + option.id"
            :name="poll.title"
            v-model="option.selected"
          />
          <input
            v-else
            type="radio"
            :id="poll.id + '_' + option.id"
            :name="poll.title"
            :value="option.id"
            v-model="poll.answer"
          /><label :for="poll.id + '_' + option.id">{{ option.answer }}</label
          ><input
            v-if="option.freetext"
            v-model="poll.freetext"
            class="w-auto py-1"
            type="text"
            placeholder="Freitext"
          />
        </div>
        <div v-if="poll.comments">
          <label>Allgemeiner Kommentar:</label>
          <input type="text" v-model="poll.comment" placeholder="Kommentar" />
        </div>
      </div>

      <div class="w-full text-center">
        <button class="green mt-4" @click="saveAnswers()">Speichern</button>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/API";

export default {
  emits: ["toast", "close"],
  props: {
    polls: Array,
    contract:Object
  },

  data() {
    return {
      editablePolls: [],
    };
  },
  methods: {
    saveAnswers() {
      let polls = {};
      this.editablePolls.forEach((poll) => {
        polls[poll.id] = {
          answers: [],
          comment: poll.comment,
        };
        if (!poll.multiple) {
          polls[poll.id].answer = poll.answer;
        }
        if (poll.freetext) {
          polls[poll.id].freetext = poll.freetext;
        }
        poll.options.forEach((option) => {
          if (poll.multiple) {
            if (option.selected) {
              polls[poll.id].answers.push(option.id);
              if (option.freetext && option.freetextValue) {
                polls[poll.id].freetext = option.freetextValue;
              }
            }
          }
        });
      });
      API.answerPolls(this.contract.id, polls, (r, status) => {
      });
    },
  },
  mounted() {
    if (this.polls) {
      this.editablePolls = this.polls;
      this.editablePolls.forEach((poll) => {
        if (poll.multiple) {
          poll.options.forEach((option) => {
            option.selected = poll.answers.includes(option.id)?'on':false;
            if (option.selected) {
              setTimeout(() => {
                document.getElementById(poll.id + "_" + option.id).checked =
                  option.selected;
              }, 500);
            }
          });
        } else if (poll.answers.length == 1) {
          poll.answer = poll.answers[0];
        }
      });
    }
  },
};
</script>
