import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import LoginView from '../views/LoginView.vue'
import MensaView from '../views/MensaView.vue'
import Mealplanning from "../components/Mealplanning.vue"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainView
  },
  {
    path: '/cards',
    name: 'Mensakarten',
    component: MainView
  },
  {
    path: '/pickup',
    name: 'Abholung',
    component: MainView
  },  
  {
    path: '/mydata',
    name: 'Meine Daten',
    component: MainView
  }, {
    path: '/book',
    name: 'Leistung buchen',
    component: MainView
  },{
    path: '/services',
    name: 'Meine Leistungen',
    component: MainView
  },  {
    path: '/mealplanning',
    name: 'Essensplanung',
    component: MainView
  },
  {
    path: '/mealplanningframe',
    name: 'EssensplanungFrame',
    component: Mealplanning
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/mensa/monte',
    name: 'mensamonte',
    component: MensaView
  },
  {
    path: '/mensa/erft',
    name: 'mensaerft',
    component: MensaView
  },
  {
    path: '/mensa/gugy',
    name: 'mensagugy',
    component: MensaView
  },
  {
    path: '/mensa/apostel',
    name: 'mensaapostel',
    component: MensaView
  },
  {
    path: '/mensa/schauerte',
    name: 'mensaschauerte',
    component: MensaView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
